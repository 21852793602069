import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import theme from "../../theme/theme.yaml";

import Item from "./Item";

const Blog = (props) => {
  const { posts } = props;

  return (
    <StyledFragment>
      <main className="main">
        <ul>
          {posts.map((post) => {
            const {
              node,
              node: {
                fields: { slug },
              },
            } = post;
            return <Item key={slug} post={node} theme={theme} />;
          })}
        </ul>
      </main>
    </StyledFragment>
  );
};

Blog.propTypes = {
  posts: PropTypes.array.isRequired,
  // theme: PropTypes.object.isRequired,
};

export default Blog;

const StyledFragment = styled.div`
    .main {
      padding: 0 ${theme.space.inset.default};
    }

    ul {
      list-style: none;
      margin: 0 auto;
      padding: ${`calc(${theme.space.default} * 1.5) 0 calc(${theme.space.default} * 0.5)`};
    }

    /* @above tablet {
      .main {
        padding: 0 ${`0 calc(${theme.space.default} * 1.5)`};
      }
      ul {
        max-width: ${theme.text.maxWidth.tablet};
      }
    } */
    @media screen and (min-width: 700px) {
      .main {
        padding: 0 ${`0 calc(${theme.space.default} * 1.5)`};
      }
      ul {
        max-width: ${theme.text.maxWidth.desktop};
      }
    }
  `;
