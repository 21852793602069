import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
// import { ThemeContext } from "../layouts";
import Blog from "../components/Blog";
// import Hero from "../components/Hero";
import Seo from "../components/Seo";

const title = "Blog - PlagiaShield";
const description =
    "Discover all PlagiaShield blog posts about content marketing, SEO, and copyrights.";

class IndexPage extends React.Component {
    separator = React.createRef();

    scrollToContent = (e) => {
        this.separator.current.scrollIntoView({ block: "start", behavior: "smooth" });
    };

    render() {
        const {
            data: {
                posts: { edges: posts = [] },

                site: {
                    siteMetadata: { facebook },
                },
            },
        } = this.props;
        // console.log("posts from blog.js: ", posts);

        return (
            <React.Fragment>
                <h1 className="display-4 text-center">Blog</h1>
                <Blog posts={posts} />
                <Seo manualTitle={title} manualDescription={description} manualSlug="/blog" />
            </React.Fragment>
        );
    }
}

IndexPage.propTypes = {
    data: PropTypes.object.isRequired,
};

export default IndexPage;

// eslint-disable-next-line no-undef
export const query = graphql`
  query IndexQuery {
    posts: allMdx(
      filter: {internal: {}, fields: {source: {eq: "posts"}}}
      sort: { fields: [fields___prefix], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            prefix
          }
          frontmatter {
            title
            category
            author
            description
            cover {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 800, maxHeight: 360) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
    # bgDesktop: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
    #   resize(width: 1200, quality: 90, cropFocus: CENTER) {
    #     src
    #   }
    # }
    # bgTablet: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
    #   resize(width: 800, height: 1100, quality: 90, cropFocus: CENTER) {
    #     src
    #   }
    # }
    # bgMobile: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
    #   resize(width: 450, height: 850, quality: 90, cropFocus: CENTER) {
    #     src
    #   }
    # }
  }
`;

// hero-background